<template>
  <div>
    <c-table
      ref="table"
      title="직무스트레스 평가표"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="user.results"
      :gridHeight="gridHeight"
      :isTop="false"
      :usePaging="false"
      :filtering="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      @table-data-change="tableDataChange"
      @headerDataChange="headerDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="user"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" /> -->
        </q-btn-group>
      </template>
    </c-table>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            :flat="true"
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="user"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
// 스크롤시 저장버튼(최초)
import mixinCommon from '@/js/mixin-page-common'
export default {
  name: 'jobStressPlanDetail',
  mixins: [mixinCommon],
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaJobStressAssessmentUserId: '',
      }),
    },
    // 스크롤시 저장버튼(최초)
    eventScroll: {
      type: Object,
      default(){
        return {
          position: null,
          direction: null,
          save: null,
          isSave: false,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      user: {
        heaJobStressAssessmentUserId: '',  // 직무스트레스 평가자 일련번호
        heaJobStressPlanId: '',  // 직무스트레스 계획 일련번호
        userId: '',  // 평가자id
        userName: '',  // 평가자
        deptCd: '',  // 평가자부서코드
        deptName: '',  // 평가자부서
        sexCd: '',  // 성별
        physicalEnv: '',  // (점수)물리적환경
        jobDemand: '',  // (점수)직무요구
        jobAutonomy: '',  // (점수)직무자율
        relationship: '',  // (점수)관계갈등
        jobInsecurity: '',  // (점수)직무불안정
        organizational: '',  // (점수)조직체계
        inadequate: '',  // (점수)보상부적절
        workplace: '',  // (점수)직장문화
        manageTargetGroupFlag: '',  // 관리대상군 여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 등록자 ID
        results: [], // 결과표
      },
      grid: {
        merge: [
          { index: 0, colName: 'heaStressCheckClassName', }
        ],
        columns: [
          {
            name: 'heaStressCheckClassName',
            field: 'heaStressCheckClassName',
            label: '구분',
            align: 'center',
            sortable: false,
            style: 'width: 90px',
          },
          {
            name: 'jobStressItemName',
            field: 'jobStressItemName',
            label: '설문내용',
            align: 'left',
            sortable: false,
          },
          {
            name: 'val1',
            field: 'val1',
            label: '전혀<br/>그렇지<br/>않다',
            style: 'width:50px',
            align: "center",
            sortable: false,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
          {
            name: 'val2',
            field: 'val2',
            label: '그렇지<br/>않다',
            style: 'width:50px',
            align: "center",
            sortable: false,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
          {
            name: 'val3',
            field: 'val3',
            label: '그렇다',
            style: 'width:50px',
            align: "center",
            sortable: false,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
          {
            name: 'val4',
            field: 'val4',
            label: '매우<br/>그렇다',
            style: 'width:50px',
            align: "center",
            sortable: false,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
        ],
      },
      editable: true,
      isSave: false,
      detailUrl: '',
      listItemUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mobileSave() {
      return {
        bol: this.editable,
        uid: uid(),
      }
    },
    gridHeight() {
      return this.contentHeight ? String(this.contentHeight - 50) + 'px' : '500px'
    }
  },
  watch: {
    'eventScroll.save'() {
      // this.saveWorkPermit();
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.jobStress.user.get.url
      this.listItemUrl = selectConfig.hea.jobStress.base.item.list.url
      this.saveUrl = transactionConfig.hea.jobStress.user.save.url
      // code setting
      // list setting
      // 스크롤시 저장버튼(최초)
      this.eventScroll.position = 50
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.heaJobStressAssessmentUserId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.user, _result.data)
        if (!this.user.results || this.user.results.length === 0) {
          this.$http.url = this.listItemUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            useFlag: 'Y'
          }
          this.$http.request((_result) => {
            this.$_.forEach(_result.data, item => {
              this.user.results.push({
                heaJobStressAssessmentResultId: uid(),  // 직무스트레스 평가결과표 일련번호
                heaJobStressAssessmentUserId: this.popupParam.heaJobStressAssessmentUserId,  // 직무스트레스 평가자 일련번호
                heaJobStressPlanId: this.user.heaJobStressPlanId,  // 직무스트레스 계획 일련번호
                heaJobStressItemId: item.heaJobStressItemId,  // 직무스트레스 결과항목 일련번호
                heaStressCheckClassCd: item.heaStressCheckClassCd,
                heaStressCheckClassName: item.heaStressCheckClassName,
                jobStressItemName: item.jobStressItemName,
                val1Score: item.val1Score,
                val2Score: item.val2Score,
                val3Score: item.val3Score,
                val4Score: item.val4Score,
                val1: 'N',  // 전혀 그렇지 않다
                val2: 'N',  // 그렇지 않다
                val3: 'N',  // 그렇다
                val4: 'N',  // 매우 그렇다
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                editFlag: 'C'
              })
            })
          },);
        }
      },);
    },
    headerDataChange(props, col) {
      /**
       * 체크한 정보 제외한 나머지 정보 일괄 N 처리
       * 
       * 1. 제일 상위 정보를 통해 Y/N 구별
       * 2. Y인 경우 다른 정보 일괄 N처리
       */
      if (this.user.results && this.user.results.length > 0
        && this.user.results[0][col.name] === 'Y') {
        let _colIndex = Number(col.name.replace('val', ''))
        this.$_.forEach(this.user.results, result => {
          for (let i = 1; i <= 4; i++) {
            if (_colIndex === i) continue;
            // 전체 해제
            this.$set(result, `val${i}`, 'N');
          }
        })
      }
    },
    tableDataChange(props, col) {
      /**
       * 같은 행에 체크한 항목이 있을 시에 값을 체크한 항목을 제외한 나머지 항목은 체크 해제
       */
      if (props.row[col.name] === 'Y') {
        let _colIndex = Number(col.name.replace('val', ''))
        for (let i = 1; i <= 4; i++) {
          // 전체 해제
          this.$set(props.row, `val${i}`, 'N');
        }
        this.$set(props.row, `val${_colIndex}`, 'Y');
      }
    },
    saveInfo() {
      if (this.$comm.validTable(this.grid.columns, this.user.results)) {
        /**
         * 모든 설문내용에 답변 필요
         */
        if (this.$_.findIndex(this.user.results, result => {
          let _check = false;
          for (let i = 1; i <= 4; i++) {
            // 전체 해제
            if (result[`val${i}`] === 'Y') {
              _check = true
              break;
            }
          }
          return !_check;
        }) > -1) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: `답변하지 않은 항목이 존재합니다.`,
            type: 'warning', // success / info / warning / error
          });
          return;
        }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let promises = [
              {
                func: this.setSaveValue,
              },
              {
                func: this.saveValue
              },
            ];
            this.$comm.orderedPromise(promises);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.user.chgUserId = this.$store.getters.user.userId
        /**
         * 단락별 점수 setting
         * 
         * 영역별 환산점수 = (해당 영역의 각 문항에 주어진 점수의 합 - 문항 개수) × 100 ÷ (해당 영역의 예상 가능한 최고 총점 - 문항 개수)
         * @구분 : HEA_STRESS_CHECK_CLASS_CD
         * @해당 영역의 각 문항에 주어진 점수의 합 : class_sum_score
         * @문항 개수 : class_count
         * @해당 영역의 예상 가능한 최고 총점 : class_top_score
         * @영역별 환산점수 : class_score
         */
        this.$comm.getComboItems('HEA_STRESS_CHECK_CLASS_CD').then(_result => {
          this.$_.forEach(_result, _item => {
            let _filters = this.$_.filter(this.user.results, { heaStressCheckClassCd: _item.code })
            let class_sum_score = 0;
            let class_count = _filters && _filters.length > 0 ? _filters.length : 0;
            let class_top_score = 0;
            let class_score = 0;
            // val
            // valScore
            this.$_.forEach(_filters, _filter => {
              let _class_top_score = 0;
              for (let i = 1; i <= 4; i++) {
                if (_filter[`val${i}`] === 'Y') {
                  class_sum_score += _filter[`val${i}Score`]
                }
                if (_filter[`val${i}Score`] > _class_top_score) {
                  _class_top_score = _filter[`val${i}Score`];
                }
              }
              class_top_score += _class_top_score
            })
            class_score = (class_sum_score - class_count) * 100 / (class_top_score - class_count)
            switch(_item.code) {
              case 'HCC0000001': // 물리적환경
                this.$set(this.user, 'physicalEnv', class_score);
                break;
              case 'HCC0000005': // 직무요구
                this.$set(this.user, 'jobDemand', class_score);
                break;
              case 'HCC0000010': // 직무자율
                this.$set(this.user, 'jobAutonomy', class_score);
                break;
              case 'HCC0000015': // 관계갈등
                this.$set(this.user, 'relationship', class_score);
                break;
              case 'HCC0000020': // 직무 불안정
                this.$set(this.user, 'jobInsecurity', class_score);
                break;
              case 'HCC0000025': // 조직체계
                this.$set(this.user, 'organizational', class_score);
                break;
              case 'HCC0000030': // 보상부적절
                this.$set(this.user, 'inadequate', class_score);
                break;
              case 'HCC0000035': // 직장문화
                this.$set(this.user, 'workplace', class_score);
                break;
              default:
                // nothing..
                break;
            }
          })
          resolve(true);
        });
      });
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaJobStressAssessmentUserId', result.data)
      this.getDetail();
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>